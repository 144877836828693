<script>
import {
  required,
  email,
  helpers
} from "@vuelidate/validators";
import appConfig from "../../../app.config";
import axios from 'axios';
import qs from "qs";
import { inject } from 'vue';
import Swal from "sweetalert2";

export default {
  page: {
    title: "Login",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  setup() {
    const syncStatus = inject("sync_contact");
    let db = inject("db");

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    return {
      updateSyncStatus: (val) => {
        syncStatus.value = val;
      },
      db,
      Toast
    }
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      swRegister: null
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },  
  computed: {

  },
  async mounted(){
    if("serviceWorker" in navigator){
      navigator.serviceWorker.register("sw.js").then(reg => {
        console.log("sw.js registered", reg)
        this.swRegister = reg;
      }).catch(err => console.log("sw.js error registered", err));
    }
  },
  methods: {
    async signinapi() {
      // Loading button
      var btn = document.getElementById('btn-sign-in');
      btn.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...';
      btn.disabled = true;

      const data = qs.stringify({
        email: this.email,
        password: this.password,
        ClientID: process.env.VUE_APP_CLIENT_ID
      })

      const config = {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      }   

      const result = await axios({
        method: "POST",
        url: process.env.VUE_APP_URL_API+"member-api/login",
        data: data,
        headers: config
      })

      if (result.data.Meta.Code == 200) {
        if(!result.data.Data.Company.IsExpired){
          localStorage.clear();
          localStorage.setItem('jwt', result.data.Data.AccessToken)
          
          // saving contact from login pages to index db
          this.cleanDataDB();
          this.addCustomerToDB(result.data.Data.Contact.slice(0, 10));

          // hapus object contact dari result
          delete result.data.Data.Contact
          localStorage.setItem("user", JSON.stringify(result.data.Data));

          var company = result.data.Data.Company;
          await axios.post(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/init", {
            name: company.Name,
            kodemb: company.CompanyCode,
          }, {
            headers: {
              "clientid":"abcd1234"
            }
          });

          if(localStorage.getItem("notif") === null){
            localStorage.setItem("notif", JSON.stringify([]));
          }

          this.initSocket();

          /* try {
            
            const registration = await navigator.serviceWorker.ready;
            const messageChanel = new MessageChannel();

            messageChanel.port1.onmessage = (event) => {
              if("customerData" in event.data){
                let data = event.data.customerData;
                this.updateSyncStatus(false);
                if(data instanceof Array){
                  this.cleanDataDB();
                  this.addCustomerToDB(event.data.customerData);
                } else {
                  this.Toast.fire({
                    icon: 'error',
                    title: 'Internal Server Error'
                  })
                  console.log(data);
                }
              }
            }

            if("SyncManager" in window){
              this.updateSyncStatus(true);
              registration.sync.register("fetch-contact");
            }

            registration.active.postMessage({
              urlApi: process.env.VUE_APP_URL_API,
              clientID: process.env.VUE_APP_CLIENT_ID,
              accessToken: localStorage.getItem("jwt"),
              msgType: 'sync-customer'
            }, [messageChanel.port2])
          } catch (error) {
            console.log(error);
          } */
  
          let role = result.data.Data.Role.Code;
          if(role === 'principal'){
            this.$router.push({
              path: '/'
            });
          } else {
            this.$router.push({
              path: '/chat'
            });
          }
        } else {
          btn.innerHTML = 'Sign In';
          btn.disabled = false;
          return this.authError = "Company is expired."
        }
      } else {
        btn.innerHTML = 'Sign In';
        btn.disabled = false;
        return this.authError = result.data.Meta.Message;
      }      
    },
    toTimeStamp(date){
      return new Date(Date.parse(date)).getTime() / 1000
    },
    viewpass() { // Reveal password function
      if (document.getElementById('password-input').type == 'password') {
        document.getElementById('password-input').type = 'text'; // reveal
      }
      else {
        document.getElementById('password-input').type = 'password'; // hide
      }
    },
    addCustomerToDB(cust = []){
      return new Promise((resolve, reject) => {

        try {
          let trans = this.db.transaction(['customer'], 'readwrite')
          trans.oncomplete = e => {
            console.log("Complete : ", e);
            resolve()
          }
  
          trans.onerror = e => {
            console.log("error", e);
            reject();
          }
  
          let store = trans.objectStore('customer')
          cust.forEach(item => {
            item.id = item.ID
            store.add(item)
          })
        } catch (error) {
          this.Toast.fire({
            title: "Internal Server Error",
            icon: 'error'
          })
          console.log(error.message);
          reject(error);
        }
      })
    },
    cleanDataDB()
    {
      return new Promise((resolve, reject) => {
        let trans = this.db.transaction(['customer'], 'readwrite')
        let objectStore = trans.objectStore("customer");

        let clearRequest = objectStore.clear();
        clearRequest.onsuccess = () => {
          console.log("All Data Success removed");
          resolve();
        }

        clearRequest.onerror = () => {
          console.log("All Data Fails remove");
          reject();
        }
      })
    }
  },
  async created(){
    await this.cleanDataDB();
  }
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <!-- <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div> -->
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/icon_new.png" alt="" height="65" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Customer Relation Management
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to xmart net.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                  <div>

                  </div>

                  <form @submit.prevent="tryingToLogIn">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" class="form-control" id="email" placeholder="Enter email" v-model="email" tabindex="1"/>
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot-password" class="text-muted">Forgot
                          password?</router-link>
                      </div>
                      <label class="form-label" for="password-input">Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="password" v-model="password" class="form-control pe-5" placeholder="Enter password"
                          id="password-input" tabindex="2" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                          type="button" id="password-addon" @click="viewpass">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" tabindex="3" />
                      <label class="form-check-label" for="auth-remember-check">Remember
                        me</label>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-xmarks2 w-100" type="submit" id="btn-sign-in" tabindex="4" @click="signinapi">
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-light">
                &copy; {{ new Date().getFullYear() }} Xmart NET
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>